<template>
  <!-- begin::Users edit for SenderAdmin & SenderEmployee roles (one of his Observers) -->
  <div>
    <div class="manager-title">
      <div>
        {{
          UserID > 0 ? $t("OBSERVERS.TITLE_EDIT") : $t("OBSERVERS.TITLE_ADD")
        }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <div class="white-box shadow-box">
      <div class="manager-subtitle">
        {{ $t("OBSERVERS.SUBTITLE") }}
      </div>

      <v-form class="form" ref="formObserver" v-model="Valid" lazy-validation>
        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("MY_PROFILE.NICK") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.Nick"
              :placeholder="$t('MY_PROFILE.NICK')"
              required
              :rules="[rules.required]"
            />
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("MY_PROFILE.FULLNAME") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.Name"
              :placeholder="$t('MY_PROFILE.FULLNAME')"
              required
              :rules="[rules.required]"
            />
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("OBSERVERS.NICK_COMPANY") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.NickCompany"
              :placeholder="$t('OBSERVERS.NICK_COMPANY')"
              required
              :rules="[rules.required]"
            />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("MY_PROFILE.EMAIL") }}
            </label>
            <v-text-field
              v-if="UserID > 0"
              class="form-control"
              v-model="Fields.Email"
              :placeholder="$t('MY_PROFILE.EMAIL')"
              readonly
            >
              <template v-slot:prepend-inner>
                <inline-svg src="/media/icons/contact-gray.svg" />
              </template>
            </v-text-field>
            <v-text-field
              v-else
              class="form-control"
              v-model="Fields.Email"
              :placeholder="$t('MY_PROFILE.EMAIL')"
              :rules="[rules.required, rules.email]"
              required
            >
              <template v-slot:prepend-inner>
                <inline-svg src="/media/icons/contact-gray.svg" />
              </template>
            </v-text-field>
          </div>

          <div v-if="UserID < 0" class="col-lg-8 d-flex align-items-center">
            <inline-svg src="/media/icons/help-alt-5-primary.svg" />
            <span class="pl-3">{{ $t("USERS.NEW_EMAIL_INFO") }}</span>
          </div>
        </div>

        <div class="row button-row">
          <router-link to="/manager/myprofile?ActiveTab=observers">
            <button
              class="btn btn-pill btn-white button-form button-form-primary mr-9"
            >
              <inline-svg
                src="/media/icons/arrow-return-primary.svg"
                class="mr-3"
              />
              {{ $t("GENERIC.BACK") }}
            </button>
          </router-link>

          <button
            ref="formObserverSubmit"
            class="btn btn-pill btn-tertiary button-form"
            :disabled="!Valid"
            @click="onSubmitObserver"
          >
            {{ $t("GENERIC.SAVE") }}
          </button>
        </div>
      </v-form>
    </div>
  </div>
  <!-- end::Users edit for SenderAdmin & SenderEmployee roles -->
</template>

<script>
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import UserService from "@/core/services/api/v2/user.service";

export default {
  name: "UsersSenderEdit",
  data() {
    return {
      UserID: 0,
      Valid: true,
      Fields: {
        Nick: "",
        Name: "",
        Email: "",
        NickCompany: ""
      },
      rules: {
        required: value => !!value || this.$i18n.t("VALIDATION.REQUIRED_FIELD"),
        email: value =>
          !!/.+@.+\..+/.test(value) || this.$i18n.t("VALIDATION.INVALID_EMAIL")
      }
    };
  },
  mounted() {
    // Edit (a certain "id") or create new?
    if (this.$route.params.id) {
      this.UserID = parseInt(this.$route.params.id);
      this.loadObserverData();
    } else {
      this.UserID = -1;
    }
  },
  methods: {
    loadObserverData() {
      // Show page loader
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      UserService.getObserverProfile(this.UserID).then(response => {
        if (response === 404) {
          this.$router.push({
            path: "/manager/myProfile",
            query: { ActiveTab: "observers" }
          });
        } else {
          for (var f1 in this.Fields) {
            this.Fields[f1] = response[f1];
          }
        }

        // Remove page loader
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      });
    },

    onSubmitObserver(e) {
      e.preventDefault();

      if (this.$refs.formObserver.validate()) {
        // Disable submit button
        this.Valid = false;

        // Send request:
        if (this.UserID > 0) {
          // Update Observer
          UserService.updateObserverProfile(this.UserID, this.Fields).then(
            statusCode => {
              if (statusCode >= 400) {
                let whyError = this.$i18n.t("GENERIC.TRY_LATER");
                if (statusCode === 409) {
                  whyError = this.$i18n.t("OBSERVERS.ERROR_409");
                } else if (statusCode === 400) {
                  whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
                }

                this.$bvToast.toast(whyError, {
                  title: this.$i18n.t("GENERIC.SAVE_ERROR"),
                  variant: "danger",
                  solid: true
                });
              } else {
                this.$bvToast.toast(" ", {
                  title: this.$i18n.t("GENERIC.SAVE_OK"),
                  variant: "success",
                  solid: true
                });
              }

              // Reset submit button
              this.Valid = true;
            }
          );
        } else {
          // Create new Observer: we get back his ID
          UserService.createObserverProfile(this.Fields).then(response => {
            if (response.UserID) {
              this.$bvToast.toast(" ", {
                title: this.$i18n.t("GENERIC.SAVE_OK"),
                variant: "success",
                solid: true
              });

              this.UserID = response.UserID;
              setTimeout(
                () =>
                  this.$router.push("/manager/observers/edit/" + this.UserID),
                2000
              );
            } else {
              let whyError = this.$i18n.t("GENERIC.TRY_LATER");
              if (response === 409) {
                whyError = this.$i18n.t("OBSERVERS.ERROR_409");
              } else if (response === 418) {
                whyError = this.$i18n.t("OBSERVERS.ERROR_418");
              } else if (response === 400) {
                whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
              }

              this.$bvToast.toast(whyError, {
                title: this.$i18n.t("GENERIC.SAVE_ERROR"),
                variant: "danger",
                solid: true
              });
            }

            // Reset submit button
            this.Valid = true;
          });
        }
      }
    }
  }
};
</script>
